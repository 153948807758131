.meeting-app,
#content_container,
.root-inner > .main-content {
  /* max-width: 100% !important; */
  /* max-height: 650px; */
}
.full-screen-zoom-container,
.meeting-client {
  z-index: 9999;
  /* top: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  height: 100% !important;
  z-index: 9010 !important;
  left: 60px !important;
  width: calc(100% - 60px) !important; */
}

.join-dialog {
  left: 0;
  right: 0;
  /* width: 100% !important; */
  bottom: 0;
}

.media-preview-container {
  z-index: 9920;
}

.meeting-info-container.meeting-info-container--right-side {
  right: 10px;
  top: 12px;
  height: 32px;
  z-index: 99999;
}

.meeting-info-container .meeting-info-container--left-side {
  top: 12px;
  height: 32px;
  z-index: 99999;
}