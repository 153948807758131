.meeting-info-container {
  /* display: none !important; */
}

.meeting-info-container--left-side {
  display: none !important;
}

.meeting-info-container .meeting-info-container--right-side {
  right: 10px;
  top: 12px;
  height: 32px;
  z-index: 99999;
}

#wc-content .full-screen-icon {
  cursor: pointer !important;
  position: absolute;
  right: 10px;
  top: 8px;
  z-index: 99999;
  border-bottom: none;
}

.meeting-info-container__full-screen {
  position: relative;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #202123;
  border-radius: 8px;
  margin-left: 10px;
}

.video-avatar__avatar-title {
  /* width: 50%; */
  /* height: 50%; */
  display: flex;
  text-align: center;
  justify-content: center;
}

.video-avatar__avatar-img {
  pointer-events: none;
  width: 30%;
  height: 90%;
}